var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "card-tiny-line-stats", attrs: { "body-class": "pb-50" } },
    [
      _c("h6", [_vm._v("Profit")]),
      _c("h2", { staticClass: "font-weight-bolder mb-1" }, [_vm._v(" 6,24k ")]),
      _c("vue-apex-charts", {
        attrs: {
          height: "70",
          options: _vm.statisticsProfit.chartOptions,
          series: _vm.statisticsProfit.series
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }