var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "card-statistics", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("b-card-title", [_vm._v("Statistics")]),
          _c("b-card-text", { staticClass: "mr-25 mb-0" }, [
            _vm._v(" Updated 1 month ago ")
          ])
        ],
        1
      ),
      _c(
        "b-card-body",
        { staticClass: "statistics-body" },
        [
          _c(
            "b-row",
            _vm._l(_vm.statisticsItems, function(item) {
              return _c(
                "b-col",
                {
                  key: item.icon,
                  staticClass: "mb-2 mb-md-0",
                  class: item.customClass,
                  attrs: { md: "3", sm: "6" }
                },
                [
                  _c(
                    "b-media",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-media-aside",
                        { staticClass: "mr-2" },
                        [
                          _c(
                            "b-avatar",
                            { attrs: { size: "48", variant: item.color } },
                            [
                              _c("feather-icon", {
                                attrs: { size: "24", icon: item.icon }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-media-body",
                        { staticClass: "my-auto" },
                        [
                          _c("h4", { staticClass: "font-weight-bolder mb-0" }, [
                            _vm._v(" " + _vm._s(item.title) + " ")
                          ]),
                          _c(
                            "b-card-text",
                            { staticClass: "font-small-3 mb-0" },
                            [_vm._v(" " + _vm._s(item.subtitle) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }