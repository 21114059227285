var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "2", cols: "6" } },
            [_c("card-statistic-order-chart")],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "2", cols: "6" } },
            [_c("card-statistic-profit-chart")],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "8", cols: "12" } },
            [_c("card-statistics-group")],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { xl: "2", md: "4", sm: "6" } },
            [
              _c("statistic-card-vertical", {
                attrs: {
                  icon: "EyeIcon",
                  statistic: "36.9k",
                  "statistic-title": "Views",
                  color: "info"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2", md: "4", sm: "6" } },
            [
              _c("statistic-card-vertical", {
                attrs: {
                  color: "warning",
                  icon: "MessageSquareIcon",
                  statistic: "12k",
                  "statistic-title": "Comments"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2", md: "4", sm: "6" } },
            [
              _c("statistic-card-vertical", {
                attrs: {
                  color: "danger",
                  icon: "ShoppingBagIcon",
                  statistic: "97.8k",
                  "statistic-title": "Orders"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2", md: "4", sm: "6" } },
            [
              _c("statistic-card-vertical", {
                attrs: {
                  color: "primary",
                  icon: "HeartIcon",
                  statistic: "26.8",
                  "statistic-title": "Favorited"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2", md: "4", sm: "6" } },
            [
              _c("statistic-card-vertical", {
                attrs: {
                  color: "success",
                  icon: "AwardIcon",
                  statistic: "689",
                  "statistic-title": "Reviews"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "2", md: "4", sm: "6" } },
            [
              _c("statistic-card-vertical", {
                attrs: {
                  "hide-chart": "",
                  color: "danger",
                  icon: "TruckIcon",
                  statistic: "2.1k",
                  "statistic-title": "Returns"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _c("statistic-card-horizontal", {
                attrs: {
                  icon: "CpuIcon",
                  statistic: "86%",
                  "statistic-title": "CPU Usage"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _c("statistic-card-horizontal", {
                attrs: {
                  icon: "ServerIcon",
                  color: "success",
                  statistic: "1.2gb",
                  "statistic-title": "Memory Usage"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _c("statistic-card-horizontal", {
                attrs: {
                  icon: "ActivityIcon",
                  color: "danger",
                  statistic: "0.1%",
                  "statistic-title": "Downtime Ratio"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _c("statistic-card-horizontal", {
                attrs: {
                  icon: "AlertOctagonIcon",
                  color: "warning",
                  statistic: "13",
                  "statistic-title": "Issues Found"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _vm.subscribersGained.analyticsData
                ? _c("statistic-card-with-area-chart", {
                    attrs: {
                      icon: "UsersIcon",
                      statistic: _vm.kFormatter(
                        _vm.subscribersGained.analyticsData.subscribers
                      ),
                      "statistic-title": "Subscribers Gained",
                      "chart-data": _vm.subscribersGained.series
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _vm.revenueGenerated.analyticsData
                ? _c("statistic-card-with-area-chart", {
                    attrs: {
                      icon: "CreditCardIcon",
                      color: "success",
                      statistic: _vm.kFormatter(
                        _vm.revenueGenerated.analyticsData.revenue
                      ),
                      "statistic-title": "Revenue Generated",
                      "chart-data": _vm.revenueGenerated.series
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _vm.quarterlySales.analyticsData
                ? _c("statistic-card-with-area-chart", {
                    attrs: {
                      icon: "ShoppingCartIcon",
                      color: "danger",
                      statistic: _vm.kFormatter(
                        _vm.quarterlySales.analyticsData.sales
                      ),
                      "statistic-title": "Quarterly Sales",
                      "chart-data": _vm.quarterlySales.series
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3", sm: "6" } },
            [
              _vm.ordersRecevied.analyticsData
                ? _c("statistic-card-with-area-chart", {
                    attrs: {
                      icon: "PackageIcon",
                      color: "warning",
                      statistic: _vm.kFormatter(
                        _vm.ordersRecevied.analyticsData.orders
                      ),
                      "statistic-title": "Orders Received",
                      "chart-data": _vm.ordersRecevied.series
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "4", sm: "6" } },
            [
              _c("statistic-card-with-line-chart", {
                attrs: {
                  icon: "MonitorIcon",
                  statistic: "78.9k",
                  "statistic-title": "Site Traffic",
                  "chart-data": _vm.siteTraffic.series
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4", sm: "6" } },
            [
              _c("statistic-card-with-line-chart", {
                attrs: {
                  icon: "UserCheckIcon",
                  color: "success",
                  statistic: "659.8k",
                  "statistic-title": "Active Users",
                  "chart-data": _vm.activeUsers.series
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4", sm: "6" } },
            [
              _c("statistic-card-with-line-chart", {
                attrs: {
                  icon: "MailIcon",
                  color: "warning",
                  statistic: "28.7k",
                  "statistic-title": "Newsletter",
                  "chart-data": _vm.newsletter.series
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }